import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Label, Input, Table } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import CardLoader from "../components/CardLoader";
import MySearchOption from "../components/MySearchOption";
import IsoDashboardList from '../components/IsoDashboardList';

import { useDispatch, useSelector } from "react-redux";
import * as isoDashboardActions from "../actions/IsoDashboardActions";

const IsoDashboardScreen  = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.isodashboard.loading);
    const search = useSelector((state) => state.isodashboard.search);
    const mineOnly = useSelector((state) => state.isodashboard.mineOnly);

    const draftList = useSelector((state) => state.isodashboard.draftList);
    const newList = useSelector((state) => state.isodashboard.newList);
    const salesActionList = useSelector((state) => state.isodashboard.salesActionList);
    const calculateManDaysList = useSelector((state) => state.isodashboard.calculateManDaysList);
    const salesSupportList = useSelector((state) => state.isodashboard.salesSupportList);
    const customerApprovedList = useSelector((state) => state.isodashboard.customerApprovedList);
    const auditInProgressList = useSelector((state) => state.isodashboard.auditInProgressList);
    const technicalReviewList = useSelector((state) => state.isodashboard.technicalReviewList);
    const finalDecisionList = useSelector((state) => state.isodashboard.finalDecisionList);
    const completedList = useSelector((state) => state.isodashboard.completedList);
    const closedList = useSelector((state) => state.isodashboard.closedList);
    const abortedList = useSelector((state) => state.isodashboard.abortedList);

    const documentUserList = useSelector((state) => state.isodashboard.documentUserList);
    const auditUserList = useSelector((state) => state.isodashboard.auditUserList);
    const technicalUserList = useSelector((state) => state.isodashboard.technicalUserList);
    const decisionUserList = useSelector((state) => state.isodashboard.decisionUserList);

    const searchList = useSelector((state) => state.isodashboard.searchList);
    
    const user = useSelector((state) => state.login.user);

    const [fboSearch, setFBOSearch] = React.useState(search);
    const [fboMineOnly, setFBOMineOnly] = React.useState(mineOnly);

    const [showIsoDraft, setShowIsoDraft] = React.useState(user.showIsoDraft);
    const [showIsoNew, setShowIsoNew] = React.useState(user.showIsoNew);
    const [showIsoSalesAction, setShowIsoSalesAction] = React.useState(user.showIsoSalesAction);
    const [showIsoCalculateManDays, setShowIsoCalculateManDays] = React.useState(user.showIsoCalculateManDays);
    const [showIsoSalesSupport, setShowIsoSalesSupport] = React.useState(user.showIsoSalesSupport);
    const [showIsoCustomerApproved, setShowIsoCustomerApproved] = React.useState(user.showIsoCustomerApproved);
    const [showIsoAuditInProgress, setShowIsoAuditInProgress] = React.useState(user.showIsoAuditInProgress);
    const [showIsoTechnicalReview, setShowIsoTechnicalReview] = React.useState(user.showIsoTechnicalReview);
    const [showIsoFinalDecision, setShowIsoFinalDecision] = React.useState(user.showIsoFinalDecision);
    const [showIsoCompleted, setShowIsoCompleted] = React.useState(user.showIsoCompleted);
    const [showIsoClosed, setShowIsoClosed] = React.useState(user.showIsoClosed);
    const [showIsoAborted, setShowIsoAborted] = React.useState(user.showIsoAborted);
    
    React.useEffect(() => {
		dispatch(isoDashboardActions.loadIsoDashboard("", fboSearch, fboMineOnly));
    }, []);

    React.useEffect(() => {
		setFBOSearch(search);
    }, [search]);

    React.useEffect(() => {
		setFBOMineOnly(mineOnly);
    }, [mineOnly]);

    const searchTickets = () => {
        dispatch(isoDashboardActions.loadIsoDashboard("", fboSearch, fboMineOnly));

        dispatch(isoDashboardActions.setIsoDashboardFilterSearch(fboSearch));
        dispatch(isoDashboardActions.setIsoDashboardFilterMineOnly(fboMineOnly));
    }

    const showMyTicketsOnly = () => {
        if(user && (user.isReporter || user.isIsoDocumentController || user.isIsoDecisionMaker || user.isIsoAuditor || user.isIsoTechnicalReviewer)) {
            return false;
        }

        return false;
    }

    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-sm-0">TICKET LIST</h4>
                                    </div>
                                    <div className="flex-shrink-0">
                                        {!showIsoDraft && draftList.length > 0 && <span className="badge bg-dark ms-2">DRAFT: {draftList.length}</span>}
                                        {!showIsoNew && newList.length > 0 && <span className="badge bg-primary ms-2">NEW: {newList.length}</span>}
                                        {!showIsoSalesAction && salesActionList.length > 0 && <span className="badge bg-info ms-2">SALES: {salesActionList.length}</span>}
                                        {!showIsoCalculateManDays && calculateManDaysList.length > 0 && <span className="badge bg-warning ms-2">CALCULATE: {calculateManDaysList.length}</span>}
                                        {!showIsoSalesSupport && salesSupportList.length > 0 && <span className="badge bg-pd-review ms-2">SUPPORT: {salesSupportList.length}</span>}
                                        {!showIsoCustomerApproved && customerApprovedList.length > 0 && <span className="badge bg-scheduled ms-2">APPROVED: {customerApprovedList.length}</span>}
                                        {!showIsoAuditInProgress && auditInProgressList.length > 0 && <span className="badge bg-phase-1 ms-2">AUDIT: {auditInProgressList.length}</span>}
                                        {!showIsoTechnicalReview && technicalReviewList.length > 0 && <span className="badge bg-phase-2 ms-2">TECHNICAL: {technicalReviewList.length}</span>}
                                        {!showIsoFinalDecision && finalDecisionList.length > 0 && <span className="badge bg-final-decision ms-2">FINAL: {finalDecisionList.length}</span>}
                                        {!showIsoCompleted && completedList.length > 0 && <span className="badge bg-complete ms-2">COMPLETE: {completedList.length}</span>}
                                        {!showIsoClosed && closedList.length > 0 && <span className="badge bg-success ms-2">CLOSE: {closedList.length}</span>}
                                        {!showIsoAborted && abortedList.length > 0 && <span className="badge bg-secondary ms-2">ABORT: {abortedList.length}</span>}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <MySearchOption
                                        placeholder={"Search for ISO Tickets..."}
                                        value={fboSearch}
                                        searchList={searchList || []}
                                        fieldName=""
                                        onChange={setFBOSearch}
                                    />
                                    <div className={`form-check pt-2 ms-2 me-2 ${showMyTicketsOnly() ? "" : "d-none"}`}>
                                        <input type="checkbox" className="form-check-input"
                                            checked={fboMineOnly}
                                            onChange={e => setFBOMineOnly(e.target.checked)}
                                        /> My Tickets Only
                                    </div>
                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Search Tickets"
                                        onClick={() => searchTickets()}
                                    >
                                        <i className="ri-search-line" />
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <div className="tasks-board mb-3" id="kanbanboard">
                            <CardLoader loading={loading} />
                            {showIsoDraft && <IsoDashboardList title="Draft" titleClass="bg-dark" borderClass="border-dark" list={draftList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoNew && <IsoDashboardList title="New" titleClass="bg-primary" borderClass="border-primary" list={newList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoSalesAction && <IsoDashboardList title="Sales Action" titleClass="bg-info" borderClass="border-info" list={salesActionList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoCalculateManDays && <IsoDashboardList title="Calculate Man Days" titleClass="bg-warning" borderClass="border-warning" list={calculateManDaysList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoSalesSupport && <IsoDashboardList title="Sales Support" titleClass="bg-pd-review" borderClass="border-pd-review" list={salesSupportList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoCustomerApproved && <IsoDashboardList title="Customer Approved" titleClass="bg-scheduled" borderClass="border-scheduled" list={customerApprovedList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoAuditInProgress && <IsoDashboardList title="Audit In Progress" titleClass="bg-phase-1" borderClass="border-phase-1" list={auditInProgressList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoTechnicalReview && <IsoDashboardList title="Technical Review" titleClass="bg-phase-2" borderClass="border-phase-2" list={technicalReviewList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoFinalDecision && <IsoDashboardList title="Final Decision" titleClass="bg-final-decision" borderClass="border-final-decision" list={finalDecisionList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoCompleted && <IsoDashboardList title="Completed" titleClass="bg-complete" borderClass="border-complete" list={completedList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoClosed && <IsoDashboardList title="Closed" titleClass="bg-success" borderClass="border-success" list={closedList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                            {showIsoAborted && <IsoDashboardList title="Aborted" titleClass="bg-secondary" borderClass="border-secondary" list={abortedList} documentUserList={documentUserList} auditUserList={auditUserList} technicalUserList={technicalUserList} decisionUserList={decisionUserList} />}
                        </div>
                    </Row>                
                </Container>
            </div>
        </Fragment>
    );
}

export default IsoDashboardScreen;