import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

const MySearchOption = (props) => {
    const [value, setValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    const onFocusInput = (event) => {
        if(props.searchList && props.searchList.length > 0) {
            setShowDropdown(true);
        }
    }

    const onBlurInput = (event) => {
        if(showDropdown) {
            setShowDropdown(false);
        }
    }

    const onSelectValue = (value) => {
        onChangeValue(value);
        setShowDropdown(false);
    }

    const onChangeValue = (value) => {
        if(props.fieldName) {
            props.onChange(props.fieldName, value);
        }
        else {
            props.onChange(value)
        }
    }

    return (
        <React.Fragment>
            <div onBlur={e=> onBlurInput(e)} id="abc-001">
                <div className="search-box">
                    <Input type="text" className="form-control search" autoComplete="off" placeholder={props.placeholder || "Search..."}
                        value={props.value}
                        onChange={e=> onChangeValue(e.target.value)}
                        onFocus={e=> onFocusInput(e)}
                    />
                    <i className="ri-search-line search-icon"></i>
                </div>
                <div className={`dropdown-list dropdown-menu dropdown-menu-lg ${showDropdown ? "show" : "d-none"}`}>
                    <SimpleBar style={{ height: "320px" }}>
                        <div className="dropdown-header">
                            <h6 className="text-overflow text-muted mb-0 text-uppercase">Recent Searches</h6>
                        </div>
                        {(props.searchList || []).map(function(item, index){
                            return(
                                <button type="button" className="dropdown-item notify-item" key={index} onMouseDown={e=> onSelectValue(item.searchText)}>
                                    <i className="ri-search-line align-middle fs-18 text-muted me-2"></i>
                                    <span>{item.searchText}</span>
                                </button>
                            );
                        })}
                    </SimpleBar>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MySearchOption;