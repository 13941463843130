import { LAB_REPORT_SEARCH_START_LOADING, LAB_REPORT_SEARCH_END_LOADING, LAB_REPORT_SEARCH_LOADED, SET_LAB_REPORT_FILTER } from "../constants/LabReportConstants";
import { LAB_REPORT_START_LOADING, LAB_REPORT_END_LOADING, LAB_REPORT_LOADED } from "../constants/LabReportConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadLabReportSearch(filters) {
    return async (dispatch) => {
        
        await dispatch({ type: LAB_REPORT_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("LabReport/GetPaged", filters, null);
            if(data.success) {
                await dispatch({
                    type: LAB_REPORT_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: LAB_REPORT_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LAB_REPORT_SEARCH_END_LOADING });
        }
    };
}


export function setLabReportFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_LAB_REPORT_FILTER,
            payload: filter
        });
    };
}

export function loadLabReport(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: LAB_REPORT_START_LOADING });

        try {

            var data = await getApiData("LabReport/GetLabReportFBO", { id });
            if(data.success) {
                await dispatch({
                    type: LAB_REPORT_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: LAB_REPORT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LAB_REPORT_END_LOADING });
        }
    };
}

export function saveLabReport(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: LAB_REPORT_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("LabReport/SaveLabReportFBO", null, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/lab-report-edit/' + data.responseEntities[0].labReport.id);
                    dispatch(commonActions.setSuccessMessage("Lab Report Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: LAB_REPORT_LOADED,
                        payload: data.responseEntities[0]
                    });

                    dispatch(commonActions.setSuccessMessage("Lab Report Saved Successfully!"));
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: LAB_REPORT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LAB_REPORT_END_LOADING });
        }
    };
}