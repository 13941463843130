export const ISO_DASHBOARD_START_LOADING = "ISO_DASHBOARD_START_LOADING";
export const ISO_DASHBOARD_END_LOADING = "ISO_DASHBOARD_END_LOADING";

export const SET_ISO_DASHBOARD_FILTER_SEARCH = "SET_ISO_DASHBOARD_FILTER_SEARCH";
export const SET_ISO_DASHBOARD_FILTER_MINE_ONLY = "SET_ISO_DASHBOARD_FILTER_MINE_ONLY";

export const ISO_DASHBOARD_DRAFT_LOADED = "ISO_DASHBOARD_DRAFT_LOADED";
export const ISO_DASHBOARD_NEW_LOADED = "ISO_DASHBOARD_NEW_LOADED";
export const ISO_DASHBOARD_SALES_ACTION_LOADED = "ISO_DASHBOARD_SALES_ACTION_LOADED";
export const ISO_DASHBOARD_CALCULATE_MAN_DAYS_LOADED = "ISO_DASHBOARD_CALCULATE_MAN_DAYS_LOADED";
export const ISO_DASHBOARD_SALES_SUPPORT_LOADED = "ISO_DASHBOARD_SALES_SUPPORT_LOADED";
export const ISO_DASHBOARD_CUSTOMER_APPROVED_LOADED = "ISO_DASHBOARD_CUSTOMER_APPROVED_LOADED";
export const ISO_DASHBOARD_AUDIT_IN_PROGRESS_LOADED = "ISO_DASHBOARD_AUDIT_IN_PROGRESS_LOADED";
export const ISO_DASHBOARD_TECHNICAL_REVIEW_LOADED = "ISO_DASHBOARD_TECHNICAL_REVIEW_LOADED";
export const ISO_DASHBOARD_FINAL_DECISION_LOADED = "ISO_DASHBOARD_FINAL_DECISION_LOADED";
export const ISO_DASHBOARD_COMPLETED_LOADED = "ISO_DASHBOARD_COMPLETED_LOADED";
export const ISO_DASHBOARD_CLOSED_LOADED = "ISO_DASHBOARD_CLOSED_LOADED";
export const ISO_DASHBOARD_ABORTED_LOADED = "ISO_DASHBOARD_ABORTED_LOADED";

export const ISO_DASHBOARD_DOCUMENT_USER_LOADED = "ISO_DASHBOARD_DOCUMENT_USER_LOADED";
export const ISO_DASHBOARD_AUDIT_USER_LOADED = "ISO_DASHBOARD_AUDIT_USER_LOADED";
export const ISO_DASHBOARD_TECHNICAL_USER_LOADED = "ISO_DASHBOARD_TECHNICAL_USER_LOADED";
export const ISO_DASHBOARD_DECISION_USER_LOADED = "ISO_DASHBOARD_DECISION_USER_LOADED";
export const ISO_DASHBOARD_USER_SEARCH_LOADED = "ISO_DASHBOARD_USER_SEARCH_LOADED";