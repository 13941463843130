import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import PaginationComponent from "../components/PaginationComponent";
import MySearchOption from "../components/MySearchOption";
import MyDateRenderer from "../components/MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as productActions from "../actions/ProductActions";

const ProductSearchScreen  = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const loading = useSelector((state) => state.product.loading);
    const list = useSelector((state) => state.product.list);
    const filter = useSelector((state) => state.product.filter);

    const doSearch = () => {
        var copy_filter = createDeepClone(filter);
        
        if(copy_filter.pageIndex == 1) {
            dispatch(productActions.loadProductSearch(filter, navigate));
        }
        else {
            copy_filter.pageIndex = 1;
            dispatch(productActions.setProductFilter(copy_filter));
        }
    }

    React.useEffect(() => {
		dispatch(productActions.loadProductSearch(filter, navigate));
    }, [filter.pageIndex]);

    const onFilterChange = (name, value) => {
        var copy_filter = createDeepClone(filter);
        copy_filter[name] = value;
        dispatch(productActions.setProductFilter(copy_filter));
    }
    
    const onPageChange = (page) => {
        var copy_filter = createDeepClone(filter);
        copy_filter.pageIndex = page;
        dispatch(productActions.setProductFilter(copy_filter));
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Product Search" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-3">
                                        <Col xxl={6} md={12}>
                                            <MySearchOption
                                                placeholder={"Search for products..."}
                                                value={filter.search}
                                                searchList={list.searchList || []}
                                                fieldName="search"
                                                onChange={onFilterChange}
                                            />
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <select className="form-select"
                                                value={filter.active}
                                                onChange={e => onFilterChange("active", e.target.value)}
                                            >
                                                <option value={""}>All</option>
                                                <option value={true}>Active</option>
                                                <option value={false}>InActive</option>
                                            </select>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <button type="button" className="btn btn-primary btn-label w-100" 
                                                disabled={loading}
                                                onClick= {() => doSearch()}
                                            >
                                                <i className="ri-search-eye-line label-icon align-middle"></i> Search
                                            </button>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <Link to={`/product-add`} className="btn btn-primary btn-label w-100">
                                                <i className="ri-add-line label-icon align-middle"></i> Add Product
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardLoader loading={loading} />
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <Table className="table-hover align-middle mb-0">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th style={{width:10}}>&nbsp;</th>
                                                    <th>HSCode</th>
                                                    <th>Product Name</th>
                                                    <th>Description</th>
                                                    <th>Active</th>
                                                    <th>Action Date</th>
                                                    <th>Action By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.dataList.map(function(item, index){
                                                    return(
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline tasks-list-menu mb-0">
                                                                        <li className="list-inline-item">
                                                                            <Link to={`/product-edit/${item.id}`}>
                                                                                <i className="ri-pencil-fill fs-20 align-bottom text-primary"></i>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td>{item.hsCode}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.description}</td>
                                                            <td>{item.active ? "Yes" : "No"}</td>
                                                            <td><MyDateRenderer value={item.lastActionDate} /></td>
                                                            <td>{item.updatedBy}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <PaginationComponent
                                                total={list.totalCount}
                                                currentPage={filter.pageIndex}
                                                onPageChange={page => onPageChange(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default ProductSearchScreen;