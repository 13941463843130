import { CUSTOMER_SEARCH_START_LOADING, CUSTOMER_SEARCH_END_LOADING, CUSTOMER_SEARCH_LOADED, SET_CUSTOMER_FILTER } from "../constants/CustomerConstants";
import { CUSTOMER_START_LOADING, CUSTOMER_END_LOADING, CUSTOMER_LOADED } from "../constants/CustomerConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadCustomerSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: CUSTOMER_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Customer/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: CUSTOMER_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: CUSTOMER_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: CUSTOMER_SEARCH_END_LOADING });
        }
    };
}

export function setCustomerFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_CUSTOMER_FILTER,
            payload: filter
        });
    };
}

export function loadCustomer(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: CUSTOMER_START_LOADING });

        try {

            var data = await getApiData("Customer/GetCustomerFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: CUSTOMER_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: CUSTOMER_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: CUSTOMER_END_LOADING });
        }
    };
}

export function saveCustomer(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: CUSTOMER_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("Customer/SaveCustomerFBO", null, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/customer-edit/' + data.responseEntities[0].customer.id);
                    dispatch(commonActions.setSuccessMessage("Customer Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: CUSTOMER_LOADED,
                        payload: data.responseEntities[0]
                    });

                    dispatch(commonActions.setSuccessMessage("Customer Saved Successfully!"));
                }
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: CUSTOMER_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: CUSTOMER_END_LOADING });
        }
    };
}