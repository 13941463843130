import { TICKET_SEARCH_START_LOADING, TICKET_SEARCH_END_LOADING } from "../constants/TicketConstants";
import { TICKET_SEARCH_LOADED, TICKET_SEARCH_REVW_LOADED, TICKET_SEARCH_SCOC_LOADED, TICKET_SEARCH_PCOC_LOADED, TICKET_SEARCH_FA_LOADED, TICKET_SEARCH_SOC_LOADED } from "../constants/TicketConstants";
import { SET_TICKET_FILTER, SET_TICKET_REVW_FILTER, SET_TICKET_SCOC_FILTER, SET_TICKET_PCOC_FILTER, SET_TICKET_FA_FILTER, SET_TICKET_SOC_FILTER } from "../constants/TicketConstants";
import { TICKET_TAG_START_LOADING, TICKET_TAG_END_LOADING, TICKET_TAG_LOADED } from "../constants/TicketConstants";
import { TICKET_START_LOADING, TICKET_END_LOADING, TICKET_LOADED } from "../constants/TicketConstants";
import { TICKET_ITEM_START_LOADING, TICKET_ITEM_END_LOADING, TICKET_ITEM_LOADED } from "../constants/TicketConstants";
import { TICKET_PRODUCT_START_LOADING, TICKET_PRODUCT_END_LOADING, TICKET_PRODUCT_LOADED } from "../constants/TicketConstants";
import { LINKED_TICKET_START_LOADING, LINKED_TICKET_END_LOADING, LINKED_TICKET_LOADED } from "../constants/TicketConstants";
import { RELATED_TICKET_START_LOADING, RELATED_TICKET_END_LOADING, RELATED_TICKET_LOADED } from "../constants/TicketConstants";
import { TICKET_USER_START_LOADING, TICKET_USER_END_LOADING, TICKET_USER_LOADED } from "../constants/TicketConstants";
import { TICKET_INVOICE_START_LOADING, TICKET_INVOICE_END_LOADING, TICKET_INVOICE_LOADED } from "../constants/TicketConstants";
import { TICKET_INVOICE_CONTRACT_START_LOADING, TICKET_INVOICE_CONTRACT_END_LOADING, TICKET_INVOICE_CONTRACT_LOADED } from "../constants/TicketConstants";
import { TICKET_INVOICE_PRODUCT_START_LOADING, TICKET_INVOICE_PRODUCT_END_LOADING, TICKET_INVOICE_PRODUCT_LOADED } from "../constants/TicketConstants";
import { TICKET_FORM_START_LOADING, TICKET_FORM_END_LOADING, TICKET_FORM_LOADED } from "../constants/TicketConstants";

import { getApiData, postApiJsonData, postApiFormData, downloadFile } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadTicketSearch(filters, type) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/GetPaged", filters, null);
            
            if(data.success) {
                if(type === "REVW") {
                    await dispatch({
                        type: TICKET_SEARCH_REVW_LOADED,
                        payload: {
                            dataList: data.responseEntities[0].dataList
                            , searchList: data.responseEntities[0].userSearches
                            , totalCount: data.responseEntities[0].totalCount
                        }
                    });
                }
                else if(type === "SCOC") {
                    await dispatch({
                        type: TICKET_SEARCH_SCOC_LOADED,
                        payload: {
                            dataList: data.responseEntities[0].dataList
                            , searchList: data.responseEntities[0].userSearches
                            , totalCount: data.responseEntities[0].totalCount
                        }
                    });
                }
                else if(type === "PCOC") {
                    await dispatch({
                        type: TICKET_SEARCH_PCOC_LOADED,
                        payload: {
                            dataList: data.responseEntities[0].dataList
                            , searchList: data.responseEntities[0].userSearches
                            , totalCount: data.responseEntities[0].totalCount
                        }
                    });
                }
                else if(type === "FA") {
                    await dispatch({
                        type: TICKET_SEARCH_FA_LOADED,
                        payload: {
                            dataList: data.responseEntities[0].dataList
                            , searchList: data.responseEntities[0].userSearches
                            , totalCount: data.responseEntities[0].totalCount
                        }
                    });
                }
                else if(type === "SOC") {
                    await dispatch({
                        type: TICKET_SEARCH_SOC_LOADED,
                        payload: {
                            dataList: data.responseEntities[0].dataList
                            , searchList: data.responseEntities[0].userSearches
                            , totalCount: data.responseEntities[0].totalCount
                        }
                    });
                }
                else {
                    await dispatch({
                        type: TICKET_SEARCH_LOADED,
                        payload: {
                            dataList: data.responseEntities[0].dataList
                            , searchList: data.responseEntities[0].userSearches
                            , totalCount: data.responseEntities[0].totalCount
                        }
                    });
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_SEARCH_END_LOADING });
        }
    };
}

export function setTicketFilter(filter, type) {
    if(type === "REVW") {
        return async (dispatch) => {
            await dispatch({
                type: SET_TICKET_REVW_FILTER,
                payload: filter
            });
        };
    }
    else if(type === "SCOC") {
        return async (dispatch) => {
            await dispatch({
                type: SET_TICKET_SCOC_FILTER,
                payload: filter
            });
        };
    }
    else if(type === "PCOC") {
        return async (dispatch) => {
            await dispatch({
                type: SET_TICKET_PCOC_FILTER,
                payload: filter
            });
        };
    }
    else if(type === "FA") {
        return async (dispatch) => {
            await dispatch({
                type: SET_TICKET_FA_FILTER,
                payload: filter
            });
        };
    }
    else if(type === "SOC") {
        return async (dispatch) => {
            await dispatch({
                type: SET_TICKET_SOC_FILTER,
                payload: filter
            });
        };
    }
    else {
        return async (dispatch) => {
            await dispatch({
                type: SET_TICKET_FILTER,
                payload: filter
            });
        };
    }
}

export function loadTags() {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_TAG_START_LOADING });

        try {

            var data = await getApiData("Lookup/GetTagLookup");
            
            if(data.success) {
                await dispatch({
                    type: TICKET_TAG_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_TAG_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_TAG_END_LOADING });
        }
    };
}

export function loadTicket(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetTicketFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: TICKET_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                    dispatch(commonActions.setErrorMessage(data.errorDesc));
                }
                else if(data.errorCode == "402") {
                    if(data.companyId && data.companyId > 0) {
                        navigate('/invalid-company/ticket/' + data.companyId + "/" + data.moduleName + "/" + id);
                    }
                    else {
                        navigate('/invalid-company');
                        dispatch(commonActions.setErrorMessage(data.errorDesc));
                    }
                }
                else {
                    dispatch(commonActions.setErrorMessage(data.errorDesc));
                }
            }
            
			await dispatch({ type: TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_END_LOADING });
        }
    };
}

export function saveTicket(fbo, status, message, invoice, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("Ticket/SaveTicketFBO", { status, message }, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/request-edit/' + data.responseEntities[0].ticket.id);
                    dispatch(commonActions.setSuccessMessage("Ticket Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: TICKET_LOADED,
                        payload: data.responseEntities[0]
                    });
                    
                    if(invoice) {
                        dispatch(createInvoice(fbo.ticket.id, navigate));
                    }
                    else {
                        dispatch(commonActions.setSuccessMessage("Ticket Saved Successfully!"));
                    }
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_END_LOADING });
        }
    };
}

export async function validateTicketSubmission(ticketForm, ticketId) {
    var errorMessages = [];

    try {

        var data = await postApiJsonData("Ticket/ValidateTicketSubmission", { ticketId }, ticketForm);
        
        if(data.success) {
            errorMessages = data.responseEntities[0].errorMessageList;
        }
        else {
            alert(data.errorDesc);
        }
    } 
    catch (error) {
        alert(error);
    }

    return errorMessages;
}

export function assignMeTicket(ticketId, userId, assignMe, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/AssignTicket", { ticketId, userId, assignMe }, null);
            
            if(data.success) {
                dispatch(loadTicket(ticketId, navigate));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_END_LOADING });
        }
    };
}

export function loadTicketItems(ticketId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_ITEM_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetTicketItems", { ticketId });
            
            if(data.success) {
                await dispatch({
                    type: TICKET_ITEM_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_ITEM_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_ITEM_END_LOADING });
        }
    };
}

export function changeTicketStatus(ticketId, childIds, status) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_ITEM_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/ChangeTicketStatus", { ticketId, childIds, status }, null);
            
            if(data.success) {
                await dispatch({
                    type: TICKET_ITEM_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_ITEM_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_ITEM_END_LOADING });
        }
    };
}

export function removeTicketFromContainer(ticketId, childTicketId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_ITEM_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/RemoveTicketFromContainer", { ticketId, childTicketId }, null);
            
            if(data.success) {
                await dispatch({
                    type: TICKET_ITEM_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_ITEM_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_ITEM_END_LOADING });
        }
    };
}

export function loadTicketProducts(ticketId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_PRODUCT_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetTicketProducts", { ticketId });
            
            if(data.success) {
                await dispatch({
                    type: TICKET_PRODUCT_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        }
    };
}

export function addTicketProduct(ticketId, productId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_PRODUCT_START_LOADING });

        try {

            var data = await getApiData("Ticket/AddTicketProduct", { ticketId, productId }, null);
            
            if(data.success) {
                await dispatch({
                    type: TICKET_PRODUCT_LOADED,
                    payload: data.responseEntities
                });

                dispatch(commonActions.setSuccessMessage("Product Added Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        }
    };
}

export function changeTicketProduct(id, productId, ticketId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_PRODUCT_START_LOADING });

        try {

            var data = await getApiData("Ticket/ChangeTicketProduct", { id, productId, ticketId }, null);
            
            if(data.success) {
                dispatch(loadTicketProducts(ticketId));

                dispatch(commonActions.setSuccessMessage("Product Updated Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        }
    };
}

export function deleteTicketProduct(id, ticketId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_PRODUCT_START_LOADING });

        try {

            var data = await getApiData("Ticket/DeleteTicketProduct", { id, ticketId }, null);
            
            if(data.success) {
                await dispatch({
                    type: TICKET_PRODUCT_LOADED,
                    payload: data.responseEntities
                });

                dispatch(commonActions.setSuccessMessage("Product Deleted Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        }
    };
}

export function saveTicketProduct(ticketProduct, ticketId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_PRODUCT_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/SaveTicketProduct", { ticketId }, ticketProduct);
            
            if(data.success) {
                await dispatch({
                    type: TICKET_PRODUCT_LOADED,
                    payload: data.responseEntities
                });

                dispatch(commonActions.setSuccessMessage("Product Updated Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_PRODUCT_END_LOADING });
        }
    };
}

export function saveChildTicket(ticketId, serviceId, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/CreateChildTicket", { ticketId, serviceId }, null);
            
            if(data.success) {
                navigate('/request-edit/' + data.responseEntities[0].ticket.id);
                dispatch(commonActions.setSuccessMessage("Ticket Created Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_END_LOADING });
        }
    };
}

export function savePayment(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_START_LOADING });

        try {

            var data = await postApiJsonData("Payment/CreatePayment", { id }, null);
            
            if(data.success) {
                navigate('/payment-edit/' + data.responseEntities[0].payment.id);
                dispatch(commonActions.setSuccessMessage("Payment Created Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_END_LOADING });
        }
    };
}

export function loadLinkedTicket(id) {
    return async (dispatch) => {
        
        await dispatch({ type: LINKED_TICKET_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetLinkedTickets", { id });
            
            if(data.success) {
                await dispatch({
                    type: LINKED_TICKET_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: LINKED_TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LINKED_TICKET_END_LOADING });
        }
    };
}

export function loadRelatedTicket(id) {
    return async (dispatch) => {
        
        await dispatch({ type: RELATED_TICKET_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetRelatedTickets", { id });
            
            if(data.success) {
                await dispatch({
                    type: RELATED_TICKET_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: RELATED_TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: RELATED_TICKET_END_LOADING });
        }
    };
}

export function loadTicketUser(id) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_USER_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetTicketUsers", { id });
            
            if(data.success) {
                await dispatch({
                    type: TICKET_USER_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_USER_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_USER_END_LOADING });
        }
    };
}

export function downloadAllDocument(id, documentName) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_START_LOADING });

        try {

            var data = await downloadFile("Document/DownloadTicketDocument", { id });
            
            if(data.success) {
                var url = window.URL.createObjectURL(data.file);
                var a = document.createElement('a');
                a.href = url;
                a.download = documentName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

            await dispatch({ type: TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_END_LOADING });
        }
    };
}

export function createInvoice(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_INVOICE_START_LOADING });

        try {

            var data = await getApiData("Invoice/CreateInvoice", { id });
            
            if(data.success) {                
                dispatch(commonActions.setSuccessMessage("Invoice Created Successfully!"));
                await dispatch(loadTicket(id, navigate));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_INVOICE_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_INVOICE_END_LOADING });
        }
    };
}

export function viewInvoice(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_INVOICE_START_LOADING });

        try {

            var data = await getApiData("Invoice/GetInvoice", { id });

            if(data.success) {
                await dispatch({
                    type: TICKET_INVOICE_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_INVOICE_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_INVOICE_END_LOADING });
        }
    };
}

export function loadTicketInvoiceContract(crmName, customerId, serviceId) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_INVOICE_CONTRACT_START_LOADING });

        try {

            await dispatch({
                type: TICKET_INVOICE_CONTRACT_LOADED,
                payload: []
            });

            var data = await getApiData("Invoice/GetContract", { crmName, customerId, serviceId });
            
            if(data.success) {
                await dispatch({
                    type: TICKET_INVOICE_CONTRACT_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_INVOICE_CONTRACT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_INVOICE_CONTRACT_END_LOADING });
        }
    };
}

export function loadTicketInvoiceProduct(crmName, customerId, serviceId, productName) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_INVOICE_PRODUCT_START_LOADING });

        try {

            await dispatch({
                type: TICKET_INVOICE_PRODUCT_LOADED,
                payload: []
            });
            
            var data = await getApiData("Invoice/GetProduct", { crmName, customerId, serviceId, productName });
            
            if(data.success) {
                await dispatch({
                    type: TICKET_INVOICE_PRODUCT_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_INVOICE_PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_INVOICE_PRODUCT_END_LOADING });
        }
    };
}

export async function addTicketCreateInvoiceNotification(ticketId) {
    var returnData = {
        success: false,
        errorMessage: "",
        successMessage: "Create Invoice Notification Sent Successfully!"
    };

    try {

        var data = await getApiData("Ticket/AddTicketCreateInvoiceNotification", { ticketId });

        if(data.success) {
            returnData.success = true;
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export async function addTicketSadadPaymentNotification(ticketId) {
    var returnData = {
        success: false,
        errorMessage: "",
        successMessage: "SADAD Payment Notification Sent Successfully!"
    };

    try {

        var data = await getApiData("Ticket/AddTicketSadadPaymentNotification", { ticketId });

        if(data.success) {
            returnData.success = true;
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export async function addTicketSadadPaidNotification(ticketId) {
    var returnData = {
        success: false,
        errorMessage: "",
        successMessage: "SADAD Paid Notification Sent Successfully!"
    };

    try {

        var data = await getApiData("Ticket/AddTicketSadadPaidNotification", { ticketId });

        if(data.success) {
            returnData.success = true;
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export async function updateTicketComplianceState(ticketId, state, commentText) {
    var returnData = {
        success: false,
        errorMessage: "",
        successMessage: state + " Label Applied Successfully!"
    };

    try {

        var data = await getApiData("Ticket/UpdateTicketComplianceState", { ticketId, state, commentText });

        if(data.success) {
            returnData.success = true;
        }
        else {
            returnData.errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.errorMessage = error;
    }

    return returnData;
}

export function loadTicketForm(ticketId, code, type) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_FORM_START_LOADING });

        try {

            await dispatch({
                type: TICKET_FORM_START_LOADING,
                payload: null
            });
            
            var data = await getApiData("Ticket/GetTicketFormFBO", { ticketId, code, type });
            
            if(data.success) {
                await dispatch({
                    type: TICKET_FORM_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_FORM_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_FORM_END_LOADING });
        }
    };
}

export function saveTicketForm(fbo, ticketId, code, type) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_FORM_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/SaveTicketFormFBO", { ticketId, code, type }, fbo);
            
            if(data.success) {
                await dispatch({
                    type: TICKET_FORM_LOADED,
                    payload: data.responseEntities[0]
                });
                
                dispatch(commonActions.setSuccessMessage("Form Saved Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: TICKET_FORM_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_FORM_END_LOADING });
        }
    };
}

export function loadProductChecklist(fbo, ticketId, code, type) {
    return async (dispatch) => {
        
        await dispatch({ type: TICKET_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetTicketFormEditFBO", { ticketId, code, type });
            
            if(data.success) {
                fbo.productChecklistForm.ticketForm = data.responseEntities[0].ticketForm;
                fbo.productChecklistForm.ticketFormData = data.responseEntities[0].ticketFormData;
                
                await dispatch({
                    type: TICKET_LOADED,
                    payload: fbo
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: TICKET_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: TICKET_END_LOADING });
        }
    };
}

export async function sendMailForm3(ticketId, to, cc, subject, message) {
    var errorMessage = "";

    try {

        var data = await getApiData("Ticket/SendMailForm3", { ticketId, to, cc, subject, message });

        if(data.success) {
            
        }
        else {
            errorMessage = data.errorDesc;
        }
    } 
    catch (error) {
        errorMessage = error;
    }

    return errorMessage;
}