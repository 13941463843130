import { createStore, combineReducers } from "redux";
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";

import LayoutReducer from "../reducers/LayoutReducer";
import CommonReducer from "../reducers/CommonReducer";
import LoginReducer from "../reducers/LoginReducer";
import UserReducer from "../reducers/UserReducer";
import CompanyReducer from "../reducers/CompanyReducer";
import KanbanReducer from "../reducers/KanbanReducer";
import DashbaordReducer from "../reducers/DashboardReducer";
import TicketReducer from "../reducers/TicketReducer";
import DocumentReducer from "../reducers/DocumentReducer";
import CommentReducer from "../reducers/CommentReducer";
import ActionReducer from "../reducers/ActionReducer";
import NotificationReducer from "../reducers/NotificationReducer";
import EmailReducer from "../reducers/EmailReducer";
import PaymentReducer from "../reducers/PaymentReducer";
import LookupReducer from "../reducers/LookupReducer";
import CustomerReducer from "../reducers/CustomerReducer";
import VendorReducer from "../reducers/VendorReducer";
import GroupReducer from "../reducers/GroupReducer";
import ProductReducer from "../reducers/ProductReducer";
import LabReportReducer from "../reducers/LabReportReducer";
import AuditReducer from "../reducers/AuditReducer";
import IsoDashboardReducer from "../reducers/IsoDashboardReducer";
import IsoRequestReducer from "../reducers/IsoRequestReducer";
import AuditorReducer from "../reducers/AuditorReducer";

const rootReducer = combineReducers(
    { 
        Layout: LayoutReducer,
        common: CommonReducer,
        login: LoginReducer,
        user: UserReducer,
        company: CompanyReducer,
        kanban: KanbanReducer,
        dashboard: DashbaordReducer,
        ticket: TicketReducer,
        document: DocumentReducer,
        comment: CommentReducer,
        action: ActionReducer,
        notification: NotificationReducer,
        email: EmailReducer,
        payment: PaymentReducer,
        lookup: LookupReducer,
        customer: CustomerReducer,
        vendor: VendorReducer,
        group: GroupReducer, 
        product: ProductReducer,
        labReport: LabReportReducer,
        audit: AuditReducer,
        isodashboard: IsoDashboardReducer,
        iso: IsoRequestReducer,
        auditor: AuditorReducer
    }
);

var store = createStore(rootReducer, applyMiddleware(thunk));

const configureStore = () => {
    return store;
}

try {
    const user_info = JSON.parse(localStorage.getItem('ticketing_user_info'));
    const user_data = JSON.parse(localStorage.getItem('ticketing_user_data'));
    const user_token = localStorage.getItem('ticketing_user_token');
        
    if(user_info && user_token) {
        store.dispatch({ type: "LOGIN_LOADED", payload: user_info });
        store.dispatch({ type: "LOGIN_TOKEN_LOADED", payload: user_token });
    }

    if(user_data) {
        store.dispatch({ type: "LOGIN_DATA_LOADED", payload: user_data });
    }
}
catch (error) {
    console.log(error);
}

export default configureStore;