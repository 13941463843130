import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { COMPANY_SEARCH_START_LOADING, COMPANY_SEARCH_END_LOADING, COMPANY_SEARCH_LOADED, SET_COMPANY_FILTER } from "../constants/CompanyConstants";
import { COMPANY_START_LOADING, COMPANY_END_LOADING, COMPANY_LOADED } from "../constants/CompanyConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        active: ""
    },
    loadingFBO: false,
    fbo: null
};

const CompanyReducer = (state = initialState, action) => {
    switch(action.type) {
        case COMPANY_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case COMPANY_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case COMPANY_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_COMPANY_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case COMPANY_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case COMPANY_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case COMPANY_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        default:
            return state;
    }
}

export default CompanyReducer;