import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { LAB_REPORT_SEARCH_START_LOADING, LAB_REPORT_SEARCH_END_LOADING, LAB_REPORT_SEARCH_LOADED, SET_LAB_REPORT_FILTER } from "../constants/LabReportConstants";
import { LAB_REPORT_START_LOADING, LAB_REPORT_END_LOADING, LAB_REPORT_LOADED } from "../constants/LabReportConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        active: "true"
    },
    loadingFBO: false,
    fbo: null,
};

const LabReportReducer = (state = initialState, action) => {
    switch(action.type) {
        case LAB_REPORT_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case LAB_REPORT_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case LAB_REPORT_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_LAB_REPORT_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case LAB_REPORT_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case LAB_REPORT_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case LAB_REPORT_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        default:
            return state;
    }
}

export default LabReportReducer;