//Search
export const AUDITOR_SEARCH_START_LOADING = "AUDITOR_SEARCH_START_LOADING";
export const AUDITOR_SEARCH_END_LOADING = "AUDITOR_SEARCH_END_LOADING";
export const AUDITOR_SEARCH_LOADED = "AUDITOR_SEARCH_LOADED";
export const SET_AUDITOR_FILTER = "SET_AUDITOR_FILTER";

//Edit
export const AUDITOR_START_LOADING = "AUDITOR_START_LOADING";
export const AUDITOR_END_LOADING = "AUDITOR_END_LOADING";
export const AUDITOR_LOADED = "AUDITOR_LOADED";
