import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { VENDOR_SEARCH_START_LOADING, VENDOR_SEARCH_END_LOADING, VENDOR_SEARCH_LOADED, SET_VENDOR_FILTER } from "../constants/VendorConstants";
import { VENDOR_START_LOADING, VENDOR_END_LOADING, VENDOR_LOADED } from "../constants/VendorConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    filter: {
        importer: {
            type: "importer",
            pageIndex: 1,
            pageSize: SEARCH_PAGE_SIZE,
            search: "",
            active: ""
        },
        exporter: {
            type: "exporter",
            pageIndex: 1,
            pageSize: SEARCH_PAGE_SIZE,
            search: "",
            active: ""
        },
        manufacturer: {
            type: "manufacturer",
            pageIndex: 1,
            pageSize: SEARCH_PAGE_SIZE,
            search: "",
            active: ""
        },
    },    
    loadingFBO: false,
    fbo: null,
};

const VendorReducer = (state = initialState, action) => {
    switch(action.type) {
        case VENDOR_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case VENDOR_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case VENDOR_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_VENDOR_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case VENDOR_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case VENDOR_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case VENDOR_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        default:
            return state;
    }
}

export default VendorReducer;