import { DOCUMENT_SEARCH_START_LOADING, DOCUMENT_SEARCH_END_LOADING, DOCUMENT_SEARCH_LOADED, SET_DOCUMENT_FILTER } from "../constants/DocumentConstants";
import { DOCUMENT_START_DOWNLOADING, DOCUMENT_END_DOWNLOADING } from "../constants/DocumentConstants";

import { getApiData, postApiJsonData, postApiFormData, downloadFile } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadDocumentSearch(filters) {
    return async (dispatch) => {
        
        await dispatch({ type: DOCUMENT_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Document/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: DOCUMENT_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: DOCUMENT_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: DOCUMENT_SEARCH_END_LOADING });
        }
    };
}

export function setDocumentFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_DOCUMENT_FILTER,
            payload: filter
        });
    };
}

export async function loadDocument(refId, refName, parentId, parentName) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };
    
    try {

        var data = await getApiData("Document/GetDocumentFBO", { refId, refName, parentId, parentName });
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }

    return documentData;
}

export async function saveDocument(formData) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };

    try {

        var data = await postApiFormData("Document/SaveDocument", null, formData);

        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }
    
    return documentData;
}

export async function loadTicketPhoto(refId, refName, parentId, parentName) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };

    try {

        var data = await getApiData("Document/GetTicketPhotoFBO", { refId, refName, parentId, parentName });
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }

    return documentData;
}

export async function saveTicketPhoto(formData) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };

    try {

        var data = await postApiFormData("Document/SaveTicketPhoto", null, formData);
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }

    return documentData;
}

export async function loadProductPhoto(refId, refName, parentId, parentName) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };

    try {

        var data = await getApiData("Document/GetProductPhotoFBO", { refId, refName, parentId, parentName });
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }

    return documentData;
}

export async function saveProductPhoto(formData) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };

    try {

        var data = await postApiFormData("Document/SaveProductPhoto", null, formData);
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }

    return documentData;
}

export async function deleteProductPhoto(id, refId, refName, parentId, parentName) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };

    try {

        var data = await getApiData("Document/DeleteProductPhoto", { id, refId, refName, parentId, parentName });
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }

    return documentData;
}

export async function loadServiceDocument(serviceId, refId, refName, parentId, parentName, isProduct) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };
        
    try {

        var data = await getApiData("Document/GetServiceDocumentFBO", {serviceId, refId, refName, parentId, parentName, isProduct });
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }
    
    return documentData;
}

export async function saveServiceDocument(formData) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };

    try {

        var data = await postApiFormData("Document/SaveServiceDocument", null, formData);
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }

    return documentData;
}

export async function deleteServiceDocument(id, serviceId, refId, refName, parentId, parentName, isProduct) {
    var documentData = {
        fbo: null
        , list: []
        , error: null
    };
        
    try {

        var data = await getApiData("Document/DeleteServiceDocument", { id, serviceId, refId, refName, parentId, parentName, isProduct });
        
        if(data.success) {
            documentData.fbo = data.responseEntities[0].document;
            documentData.list = data.responseEntities[0].documentList;
        }
        else {
            documentData.error = data.errorDesc;
        }
    } 
    catch (error) {
        documentData.error = error;
    }
    
    return documentData;
}

export function downloadDocument(id, documentName) {
    return async (dispatch) => {
        
        await dispatch({ type: DOCUMENT_START_DOWNLOADING });

        try {

            var data = await downloadFile("Document/DownloadDocument", { id });
            
            if(data.success) {
                var url = window.URL.createObjectURL(data.file);
                var a = document.createElement('a');
                a.href = url;
                a.download = documentName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

            await dispatch({ type: DOCUMENT_END_DOWNLOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: DOCUMENT_END_DOWNLOADING });
        }
    };
}

export function downloadServiceTemplate(templateName) {
    return async (dispatch) => {
        
        await dispatch({ type: DOCUMENT_START_DOWNLOADING });

        try {

            var data = await downloadFile("Document/DownloadServiceTemplate", { templateName });
            
            if(data.success) {
                var url = window.URL.createObjectURL(data.file);
                var a = document.createElement('a');
                a.href = url;
                a.download = templateName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

            await dispatch({ type: DOCUMENT_END_DOWNLOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: DOCUMENT_END_DOWNLOADING });
        }
    };
}

export function downloadServiceDocument(id, documentName) {
    return async (dispatch) => {
        
        await dispatch({ type: DOCUMENT_START_DOWNLOADING });

        try {

            var data = await downloadFile("Document/DownloadDocument", { id });
            
            if(data.success) {
                var url = window.URL.createObjectURL(data.file);
                var a = document.createElement('a');
                a.href = url;
                a.download = documentName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

            await dispatch({ type: DOCUMENT_END_DOWNLOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: DOCUMENT_END_DOWNLOADING });
        }
    };
}