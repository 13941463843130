import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import CardLoader from "../components/CardLoader";

import { useDispatch, useSelector } from "react-redux";
import * as loginActions from "../actions/LoginActions";
import * as commonActions from "../actions/CommonActions";

const InvalidCompany  = () => {
    const dispatch = useDispatch();
    const { type, companyId, moduleName, id } = useParams();
    
    const [loadingLocal, setLoadingLocal] = useState(false);

    useEffect(async () => {
        if(companyId && companyId > 0 && moduleName && moduleName.length > 0 && id && id > 0) {
            setLoadingLocal(true);
            
            var data = await loginActions.changeCompanyAndModule(companyId, moduleName);
            if(data.success) {
                if(type === "iso") {
                    window.location.href = "/iso-request-edit/" + id;
                }
                else if (type === "ticket") {
                    window.location = "/request-edit/" + id;
                }
                else {
                    window.location = "/";
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.error));
            }

            setLoadingLocal(false);
        }
    }, [type, companyId, moduleName, id]);

    return(
        <React.Fragment>
            <div className={"auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"}>
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl={5}>
                                <Card className="overflow-hidden">
                                    <CardLoader loading={loadingLocal} />
                                    {loadingLocal &&
                                        <CardBody className="p-4">
                                            <div className="text-center">
                                                <h4 className="text-primary">Changing Company/Module</h4>
                                            </div>
                                        </CardBody>
                                    }
                                    {!loadingLocal &&
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <lord-icon className="avatar-xl"
                                                src="https://cdn.lordicon.com/spxnqpau.json"
                                                trigger="loop"
                                                colors="primary:#405189,secondary:#0ab39c"
                                                style={{ width: "120px", height: "120px" }}>
                                            </lord-icon>
                                            <h1 className="text-primary mb-4">Oops !</h1>
                                            <h4 className="text-uppercase">Sorry, Invalid Company 😭</h4>
                                            <p className="text-muted mb-4">Please change your company and try again!</p>
                                            <Link to="/" className="btn btn-primary"><i className="mdi mdi-home me-1"></i>Back to home</Link>
                                        </div>
                                    </CardBody>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default InvalidCompany;