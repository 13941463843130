import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { DOCUMENT_SEARCH_START_LOADING, DOCUMENT_SEARCH_END_LOADING, DOCUMENT_SEARCH_LOADED, SET_DOCUMENT_FILTER } from "../constants/DocumentConstants";
import { DOCUMENT_START_DOWNLOADING, DOCUMENT_END_DOWNLOADING } from "../constants/DocumentConstants";

const initialState = {
    loading: false,
    downloading: false,
    list: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: ""
    }
};

const DocumentReducer = (state = initialState, action) => {
    switch(action.type) {
        case DOCUMENT_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case DOCUMENT_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case DOCUMENT_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_DOCUMENT_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case DOCUMENT_START_DOWNLOADING:
            return {
                ...state,
                downloading: true
            };
        case DOCUMENT_END_DOWNLOADING:
            return {
                ...state,
                downloading: false
            };
        default:
            return state;
    }
}

export default DocumentReducer;