import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { USER_SEARCH_START_LOADING, USER_SEARCH_END_LOADING, USER_SEARCH_LOADED, SET_USER_FILTER } from "../constants/UserConstants";
import { USER_START_LOADING, USER_END_LOADING, USER_LOADED } from "../constants/UserConstants";
import { USER_PROFILE_START_LOADING, USER_PROFILE_END_LOADING, USER_PROFILE_LOADED } from "../constants/UserConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        active: ""
    },
    loadingFBO: false,
    fbo: null,
    loadingProfile: false,
    profile: null
};

const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case USER_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case USER_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case USER_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_USER_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case USER_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case USER_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case USER_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        case USER_PROFILE_START_LOADING:
            return {
                ...state,
                loadingProfile: true
            };
        case USER_PROFILE_END_LOADING:
            return {
                ...state,
                loadingProfile: false
            };
        case USER_PROFILE_LOADED:
            return {
                ...state,
                profile: action.payload
            };
        default:
            return state;
    }
}

export default UserReducer;