import { GROUP_SEARCH_START_LOADING, GROUP_SEARCH_END_LOADING, GROUP_SEARCH_LOADED, SET_GROUP_FILTER } from "../constants/GroupConstants";
import { GROUP_START_LOADING, GROUP_END_LOADING, GROUP_LOADED } from "../constants/GroupConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadGroupSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: GROUP_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Group/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: GROUP_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: GROUP_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: GROUP_SEARCH_END_LOADING });
        }
    };
}

export function setGroupFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_GROUP_FILTER,
            payload: filter
        });
    };
}

export function loadGroup(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: GROUP_START_LOADING });

        try {

            var data = await getApiData("Group/GetGroupFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: GROUP_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: GROUP_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: GROUP_END_LOADING });
        }
    };
}

export function saveGroup(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: GROUP_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("Group/SaveGroupFBO", null, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/group-edit/' + data.responseEntities[0].group.id);
                    dispatch(commonActions.setSuccessMessage("Group Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: GROUP_LOADED,
                        payload: data.responseEntities[0]
                    });

                    dispatch(commonActions.setSuccessMessage("Group Saved Successfully!"));
                }
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: GROUP_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: GROUP_END_LOADING });
        }
    };
}

export function addGroupUser(fbo, userId, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: GROUP_START_LOADING });

        try {

            var data = await getApiData("Group/AddGroupUser", { groupId: fbo.group.id, userId: userId }, fbo);
            
            if(data.success) {
                fbo.groupUsers = data.responseEntities;

                await dispatch({
                    type: GROUP_LOADED,
                    payload: fbo
                });

                dispatch(commonActions.setSuccessMessage("Group User Added Successfully!"));
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: GROUP_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: GROUP_END_LOADING });
        }
    };
}

export function deleteGroupUser(fbo, groupUserId, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: GROUP_START_LOADING });

        try {

            var data = await getApiData("Group/RemoveGroupUser", { groupId: fbo.group.id, groupUserId: groupUserId });
            
            if(data.success) {
                fbo.groupUsers = data.responseEntities;

                await dispatch({
                    type: GROUP_LOADED,
                    payload: fbo
                });

                dispatch(commonActions.setSuccessMessage("Group User Deleted Successfully!"));
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: GROUP_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: GROUP_END_LOADING });
        }
    };
}

export function changeGroupUser(fbo, groupUserId, active, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: GROUP_START_LOADING });

        try {

            var data = await getApiData("Group/ChangeGroupUser", { groupId: fbo.group.id, groupUserId: groupUserId, active: active });
            
            if(data.success) {
                fbo.groupUsers = data.responseEntities;

                await dispatch({
                    type: GROUP_LOADED,
                    payload: fbo
                });

                dispatch(commonActions.setSuccessMessage("Group User Saved Successfully!"));
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: GROUP_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: GROUP_END_LOADING });
        }
    };
}