export const KANBAN_START_LOADING = "KANBAN_START_LOADING";
export const KANBAN_END_LOADING = "KANBAN_END_LOADING";

export const SET_KANBAN_FILTER_SEARCH = "SET_KANBAN_FILTER_SEARCH";
export const SET_KANBAN_FILTER_SERVICE_ID = "SET_KANBAN_FILTER_SERVICE_ID";
export const SET_KANBAN_FILTER_MINE_ONLY = "SET_KANBAN_FILTER_MINE_ONLY";

export const KANBAN_DRAFT_LOADED = "KANBAN_DRAFT_LOADED";
export const KANBAN_NEW_LOADED = "KANBAN_NEW_LOADED";
export const KANBAN_INPROGRESS_LOADED = "KANBAN_INPROGRESS_LOADED";
export const KANBAN_SALES_ACTION_LOADED = "KANBAN_SALES_ACTION_LOADED";
export const KANBAN_PD_REVIEW_LOADED = "KANBAN_PD_REVIEW_LOADED";
export const KANBAN_SAMPLING_LOADED = "KANBAN_SAMPLING_LOADED";
export const KANBAN_SCHEDULED_LOADED = "KANBAN_SCHEDULED_LOADED";
export const KANBAN_WAITING_CORRECTIVE_ACTION_LOADED = "KANBAN_WAITING_CORRECTIVE_ACTION_LOADED";
export const KANBAN_PHASE_1_EVALUATION_LOADED = "KANBAN_PHASE_1_EVALUATION_LOADED";
export const KANBAN_INSPECTION_LOADED = "KANBAN_INSPECTION_LOADED";
export const KANBAN_PHASE_2_EVALUATION_LOADED = "KANBAN_PHASE_2_EVALUATION_LOADED";
export const KANBAN_FINAL_DECISION_LOADED = "KANBAN_FINAL_DECISION_LOADED";
export const KANBAN_COMPLETED_LOADED = "KANBAN_COMPLETED_LOADED";
export const KANBAN_CLOSED_LOADED = "KANBAN_CLOSED_LOADED";
export const KANBAN_STOPPED_LOADED = "KANBAN_STOPPED_LOADED";
export const KANBAN_ABORTED_LOADED = "KANBAN_ABORTED_LOADED";

export const KANBAN_GROUP_LOADED = "KANBAN_GROUP_LOADED";
export const KANBAN_GROUP_USER_LOADED = "KANBAN_GROUP_USER_LOADED";

export const KANBAN_PD_GROUP_LOADED = "KANBAN_PD_GROUP_LOADED";
export const KANBAN_PD_GROUP_USER_LOADED = "KANBAN_PD_GROUP_USER_LOADED";

export const KANBAN_USER_SEARCH_LOADED = "KANBAN_USER_SEARCH_LOADED";
