import { AUDITOR_SEARCH_START_LOADING, AUDITOR_SEARCH_END_LOADING, AUDITOR_SEARCH_LOADED, SET_AUDITOR_FILTER } from "../constants/AuditorConstants";
import { AUDITOR_START_LOADING, AUDITOR_END_LOADING, AUDITOR_LOADED } from "../constants/AuditorConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadAuditorSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: AUDITOR_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Auditor/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: AUDITOR_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , searchList: data.responseEntities[0].userSearches
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: AUDITOR_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: AUDITOR_SEARCH_END_LOADING });
        }
    };
}

export function setAuditorFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_AUDITOR_FILTER,
            payload: filter
        });
    };
}

export function loadAuditor(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: AUDITOR_START_LOADING });

        try {

            var data = await getApiData("Auditor/GetAuditorFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: AUDITOR_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: AUDITOR_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: AUDITOR_END_LOADING });
        }
    };
}

export function saveAuditor(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: AUDITOR_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("Auditor/SaveAuditorFBO", null, fbo);
            
            if(data.success) {
                await dispatch({
                    type: AUDITOR_LOADED,
                    payload: data.responseEntities[0]
                });
                
                dispatch(commonActions.setSuccessMessage("Auditor Saved Successfully!"));
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: AUDITOR_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: AUDITOR_END_LOADING });
        }
    };
}